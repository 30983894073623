@import '../../params';

.page {
	margin: 0 auto;
	max-width: $maxWidth;
	width: 100%;

	// Fix page padding for different screen sizes.
	padding: 15px 8px;
	@media #{$phoneLandscape} {
		padding: 20px 16px;
	}
	@media #{$desktop} {
		padding: 28px 24px;
	}
	
	// Remove top margin of first child.
	h1, h2, h3, p {
		&:first-child {
			margin-top: 0;
		}
	}
}