@import '../../params';

.header {
	background: $headerColor;
	box-shadow: 0 4px 8px rgba(0,0,0,.26);
	color: $headerFontColor;
	width: 100%;
	z-index: 10;

	height: 50px;
	@media #{$desktop} {
		height: 80px;
	}
	
	.contents {
		display: flex;
		flex-flow: row nowrap;
		flex-grow: 0;
		height: 100%;
		justify-content: space-between;
		margin: auto;
		max-width: $maxWidth;

		padding: 0 4px;
		@media #{$phoneLandscape} {
			padding: 0 8px;
		}
		@media #{$desktop} {
			padding: 0 12px;
		}

		.title {
			align-items: center;
			display: flex;
			flex-flow: row nowrap;

			.logoLink {
				@include notSelectable();

				.logo {
					color: $headerFontColor;
					height: 46px;
					margin-right: 12px;
					width: 46px;
					@media #{$phoneLandscape} {
						margin-right: 16px;
					}
					@media #{$desktop} {
						height: 70px;
						margin-right: 24px;
						width: 70px;
					}

					svg {
						overflow: visible;
					}
				}
			}

			h1 {
				font-weight: normal;
				margin: 0;

				font-size: 1em;
				margin-right: 4px;
				@media #{$phoneLandscape} {
					margin-right: 8px;
				}
				@media #{$desktop} {
					font-size: 1.2em;
					margin-right: 12px;
				}
			}
		}
	
		.menu {
			align-items: center;
			display: flex;
			flex-flow: row nowrap;
			height: 100%;
		}
	}
}