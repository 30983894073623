

.logInForm {
  margin: auto; }

.mapContainer {
  position: relative;
  width: 100%; }

.mapContainer #googleMap {
  margin: 20px auto;
  padding-bottom: 120%;
  /* ToDo: set to 120% unless for landscape, when it's 50%. */
  position: relative;
  width: 100%;
  z-index: 1; }
  @media (min-width: 480.01px) {
    .mapContainer #googleMap {
      padding-bottom: 50%; } }




.notifications {
  display: flex;
  flex-flow: column nowrap; }
  .notifications .notification {
    border-color: #ccc;
    border-style: solid;
    border-width: 0 0 1px 0;
    display: flex;
    flex-flow: row nowrap; }
    .notifications .notification:last-child {
      border-width: 0; }
    .notifications .notification.new {
      -webkit-animation: flash 10s 1;
              animation: flash 10s 1; }
    .notifications .notification .time {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      flex-grow: 0;
      font-weight: bold;
      justify-content: center;
      min-width: 50px;
      text-align: center; }
    .notifications .notification .message {
      flex-grow: 1;
      padding: 12px; }

@-webkit-keyframes flash {
  0% {
    background: #b5cff6; }
  50% {
    background: #b5cff6; }
  100% {
    background: transparent; } }

@keyframes flash {
  0% {
    background: #b5cff6; }
  50% {
    background: #b5cff6; }
  100% {
    background: transparent; } }

.admin h2 {
  margin-top: 30px; }
  .admin h2:first-child {
    margin-top: 0; }

.inputRow {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 12px 0;
  width: 100%; }
  .inputRow label {
    padding: 2px;
    width: 25%; }
  .inputRow input,
  .inputRow select {
    padding: 2px;
    width: 75%; }

.square {
  background: pink;
  display: inline-block;
  height: 1.2em;
  margin-right: 0.6em;
  width: 1.2em; }


.ranking {
  position: relative; }
  .ranking .team {
    align-items: stretch;
    background: #12489a;
    border-radius: 6px;
    color: white;
    display: flex;
    flex-flow: row nowrap;
    font-weight: bold;
    height: 50px;
    position: absolute;
    -webkit-transition: top 2s;
    transition: top 2s;
    width: 100%; }
    .ranking .team .rank, .ranking .team .score {
      font-size: 24px;
      line-height: 50px;
      text-align: center; }
    .ranking .team .rank {
      width: 50px; }
    .ranking .team .score {
      width: 70px; }
    .ranking .team .teamDescription {
      flex-grow: 1;
      margin: 4px 0; }
      .ranking .team .teamDescription .teamName {
        font-size: 16px;
        height: 24px;
        line-height: 24px; }
      .ranking .team .teamDescription .teamMembers {
        font-size: 12px;
        height: 18px;
        line-height: 18px; }
    .ranking .team.changed {
      background: #2c76e6; }
    .ranking .team.ownTeam {
      background: #355c0b; }
      .ranking .team.ownTeam.changed {
        background: #69b815; }


/* Reset.less
 * Props to Eric Meyer (meyerweb.com) for his CSS reset file. We're using an adapted version here	that cuts out some of the reset HTML elements we will never need here (i.e., dfn, samp, etc).
 * ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
html, body {
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del, dfn, em, img, q, s, samp, small, strike, strong, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset, form, label, legend, button, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  line-height: 1;
  font-family: inherit; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

ol, ul {
  list-style: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: ""; }

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

a:focus {
  outline: thin dotted; }

a:hover,
a:active {
  outline: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block; }

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

audio:not([controls]) {
  display: none; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button,
input {
  line-height: normal;
  *overflow: visible; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

* {
  box-sizing: border-box; }

html, body, #root {
  height: 100%; }

body {
  background: #eee;
  color: #000;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.4;
  font-size: 13px; }
  @media (min-width: 960.01px) {
    body {
      font-size: 16px; } }

a {
  color: #12489a;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none; }
  a:hover, a:active {
    text-decoration: underline; }
  a, a:active, a:focus {
    outline: none; }

p {
  line-height: inherit;
  margin: 0 0 12px 0;
  text-align: justify; }

.btnRow {
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 1rem;
  width: 100%; }
  .btnRow input,
  .btnRow div {
    margin: 0 0.2rem;
    width: 50%; }
    .btnRow input:first-child,
    .btnRow div:first-child {
      margin-left: 0; }
    .btnRow input:last-child,
    .btnRow div:last-child {
      margin-right: 0; }

.inputRow {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem; }
  .inputRow label,
  .inputRow input,
  .inputRow select,
  .inputRow div {
    width: 100%; }
  .inputRow input, .inputRow select {
    border: 1px #12489a solid;
    border-radius: 0.25rem;
    padding: 0.4rem; }
    .inputRow input.correct, .inputRow select.correct {
      border-color: #83e51b; }
    .inputRow input.wrong, .inputRow select.wrong {
      border-color: #ff0022; }

.btn {
  background: #12489a;
  border-color: #0d336c;
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 0;
  color: white;
  cursor: pointer;
  display: inline-block;
  line-height: 1.4;
  padding: 0.4rem 0.8rem;
  text-align: center;
  touch-action: manipulation;
  -webkit-transition: background 200ms, border-color 200ms;
  transition: background 200ms, border-color 200ms;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle; }
  .btn.inline {
    padding: 0 6px;
    vertical-align: top; }
  .btn.selected {
    cursor: default; }
    .btn.selected:hover, .btn.selected.active {
      background: #12489a;
      border-color: #0d336c; }
  .btn.selectable {
    background: #3d516f;
    border-color: #2b394e; }
  .btn:hover, .btn.active {
    background: #175dc8;
    border-color: #12489a; }
  .btn.active {
    cursor: default; }
  .btn[disabled] {
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.25; }

strong {
  font-weight: bold; }

h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 16px 0 16px 0; }

ul {
  list-style-position: inside;
  list-style-type: disc; }
  ul li {
    margin-bottom: 4px; }

.app {
  background: #eee;
  color: #000;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  min-height: 100%;
  overflow: hidden; }

.header {
  background: #12489a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.26);
  color: #fff;
  width: 100%;
  z-index: 10;
  height: 50px; }
  @media (min-width: 960.01px) {
    .header {
      height: 80px; } }
  .header .contents {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 0;
    height: 100%;
    justify-content: space-between;
    margin: auto;
    max-width: 1200px;
    padding: 0 4px; }
    @media (max-width: 960px) {
      .header .contents {
        padding: 0 8px; } }
    @media (min-width: 960.01px) {
      .header .contents {
        padding: 0 12px; } }
    .header .contents .title {
      align-items: center;
      display: flex;
      flex-flow: row nowrap; }
      .header .contents .title .logoLink {
        outline: none;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
        .header .contents .title .logoLink .logo {
          color: #fff;
          height: 46px;
          margin-right: 12px;
          width: 46px; }
          @media (max-width: 960px) {
            .header .contents .title .logoLink .logo {
              margin-right: 16px; } }
          @media (min-width: 960.01px) {
            .header .contents .title .logoLink .logo {
              height: 70px;
              margin-right: 24px;
              width: 70px; } }
          .header .contents .title .logoLink .logo svg {
            overflow: visible; }
      .header .contents .title h1 {
        font-weight: normal;
        margin: 0;
        font-size: 1em;
        margin-right: 4px; }
        @media (max-width: 960px) {
          .header .contents .title h1 {
            margin-right: 8px; } }
        @media (min-width: 960.01px) {
          .header .contents .title h1 {
            font-size: 1.2em;
            margin-right: 12px; } }
    .header .contents .menu {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      height: 100%; }

.app header nav a.menuItem {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: center;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0.5;
  text-decoration: none;
  width: 40px; }
  @media (max-width: 960px) {
    .app header nav a.menuItem {
      width: 50px; } }
  @media (min-width: 960.01px) {
    .app header nav a.menuItem {
      width: 80px; } }
  .app header nav a.menuItem.active {
    cursor: auto; }
  .app header nav a.menuItem:hover, .app header nav a.menuItem.active {
    opacity: 1; }
  .app header nav a.menuItem .icon {
    height: 30px;
    width: 30px; }
    @media (min-width: 960.01px) {
      .app header nav a.menuItem .icon {
        height: 36px;
        width: 36px; } }
    .app header nav a.menuItem .icon svg {
      fill: currentColor; }
  .app header nav a.menuItem .label {
    display: none;
    font-size: 0.8em;
    margin-top: 7px; }
    @media (min-width: 960.01px) {
      .app header nav a.menuItem .label {
        display: block; } }

.page {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 15px 8px; }
  @media (max-width: 960px) {
    .page {
      padding: 20px 16px; } }
  @media (min-width: 960.01px) {
    .page {
      padding: 28px 24px; } }
  .page h1:first-child, .page h2:first-child, .page h3:first-child, .page p:first-child {
    margin-top: 0; }

