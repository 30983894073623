@import '../../params';

.ranking {
	position: relative;

	.team {
		align-items: stretch;
		background: $mainColor;
		border-radius: 6px;
		color: white;
		display: flex;
		flex-flow: row nowrap;
		font-weight: bold;
		height: 50px;
		position: absolute;
		transition: top 2s;
		width: 100%;

		.rank, .score {
			font-size: 24px;
			line-height: 50px;
			text-align: center;
		}
		.rank {
			width: 50px;
		}
		.score {
			width: 70px;
		}
		.teamDescription {
			flex-grow: 1;
			margin: 4px 0;

			.teamName {
				font-size: 16px;
				height: 24px;
				line-height: 24px;
			}
			.teamMembers {
				font-size: 12px;
				height: 18px;
				line-height: 18px;
			}
		}

		&.changed {
			background: lighten($mainColor, 20%);
		}

		&.ownTeam {
			background: darken($goodColor, 30%);

			&.changed {
				background: lighten(darken($goodColor, 30%), 20%);
			}
		}
	}
}