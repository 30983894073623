.admin {
  h2 {
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.inputRow {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 12px 0;
  width: 100%;

  label {
    padding: 2px;
    width: 25%;
  }

  input,
  select {
    padding: 2px;
    width: 75%;
  }
}
