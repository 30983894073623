@import '../../params';

.mapContainer {
  position: relative;
  width: 100%;
}

.mapContainer #googleMap {
  margin: 20px auto;
  padding-bottom: 120%; /* ToDo: set to 120% unless for landscape, when it's 50%. */
	@media #{$landscape} {
		padding-bottom: 50%;
	}
  position: relative;
  width: 100%;
  z-index: 1;
}