@import './params';

* {
  box-sizing: border-box;
}

html, body, #root {
	height: 100%;
}

body {
	background: $bgColor;
  color: $fontColor;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	line-height: $lineHeight;
	
	font-size: $phoneFontSize;
	@media #{$desktop} {
		font-size: $desktopFontSize;
	}
}

a {
  color: $headerColor;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  &:hover, &:active {
    text-decoration: underline;
  }
  &, &:active, &:focus { // To prevent a dotted border from appearing after clicking on the link.
    outline: none;
  }
}

p {
  line-height: inherit;
  margin: 0 0 12px 0;
  text-align: justify;
}

.btnRow {
  align-items: stretch;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 1rem;
  width: 100%;

  input,
  div {
    margin: 0 0.2rem;
    width: 50%;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.inputRow {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1rem;

  label,
  input,
  select,
  div {
    width: 100%;
  }

  input, select {
    border: 1px $mainColor solid;
    border-radius: 0.25rem;
    padding: 0.4rem;

    &.correct {
      border-color: $goodColor;
    }
    &.wrong {
      border-color: $badColor;
    }
  }
}

.btn {
  background: $mainColor;
  border-color: darken($mainColor, 10%);
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 0;
  color: white;
  cursor: pointer;
  display: inline-block;
  line-height: $lineHeight;
  padding: 0.4rem 0.8rem;
  text-align: center;
  touch-action: manipulation;
  transition: background 200ms, border-color 200ms;
  user-select: none;
  vertical-align: middle;

  &.inline {
		padding: 0 6px;
		vertical-align: top;
  }
  
  &.selected {
    cursor: default;
    &:hover, &.active {
      background: $mainColor;
      border-color: darken($mainColor, 10%);
    }
  }
  &.selectable {
    background: desaturate($mainColor, 50%);
    border-color: desaturate(darken($mainColor, 10%), 50%);
  }

  &:hover, &.active {
    background: lighten($mainColor, 10%);
    border-color: $mainColor;
  }

  &.active {
    cursor: default;
  }

  &[disabled] {
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.25;
  }
}

strong {
  font-weight: bold;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 16px 0 16px 0;
}

ul {
  list-style-position: inside;
  list-style-type: disc;

  li {
    margin-bottom: 4px;
  }
}