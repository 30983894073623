// Define site settings.

// Device media queries.
$phonePortrait: (max-width: 480px);
$phoneLandscape: (min-width: 480.01px) and (max-width: 960px);
$phone: (max-width: 960px);
$desktop: (min-width: 960.01px);
$landscape: (min-width: 480.01px);

// Colors.
$mainColor: #12489a;
$bgColor: #eee;
$fontColor: #000;
$headerColor: $mainColor; 
$headerFontColor: #fff;

$goodColor: #83e51b;
$badColor: #ff0022;
$warningColor: #ff960a;
$infoColor: $headerColor;

// Sizing.
$phoneFontSize: 13px;
$desktopFontSize: 16px;
$maxWidth: 1200px;
$lineHeight: 1.4;

// Macros.
@mixin notSelectable() { // Use this when you don't want elements to get a blue glow as if they're selected.
	outline: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}