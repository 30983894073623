@import '../../params';

.app {
	background: $bgColor;
	color: $fontColor;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	min-height: 100%;
	overflow: hidden; // To prevent scrollbars on pages with absolutely positioned elements.
}
