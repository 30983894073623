@import '../../params';

.notifications {
  display: flex;
  flex-flow: column nowrap;

  .notification {
    border-color: #ccc;
    border-style: solid;
    border-width: 0 0 1px 0;
    display: flex;
    flex-flow: row nowrap;

    &:last-child {
      border-width: 0;
		}
		
		&.new {
			animation: flash 10s 1;
		}

    .time {
      align-items: center;
      // background: red;
      display: flex;
      flex-flow: row nowrap;
      flex-grow: 0;
      font-weight: bold;
      justify-content: center;
      min-width: 50px;
      text-align: center;
    }

    .message {
      // background: blue;
      flex-grow: 1;
      padding: 12px;
    }
  }
}

@keyframes flash {
  0%   { background: lighten($mainColor, 50%); }
  50% { background: lighten($mainColor, 50%); }
  100% { background: transparent; }
}