@import '../../params';

.app header nav a.menuItem {
	align-items: center;
	color: inherit;
	cursor: pointer;
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	justify-content: center;
	@include notSelectable();
	opacity: 0.5;
	text-decoration: none;

	width: 40px;
	@media #{$phoneLandscape} {
		width: 50px;
	}
	@media #{$desktop} {
		width: 80px;
	}
	
	&.active {
		cursor: auto;
	}

	&:hover, &.active {
		opacity: 1;
	}

	.icon {
		height: 30px;
		width: 30px;
		@media #{$desktop} {
			height: 36px;
			width: 36px;
		}

		svg {
			fill: currentColor;
		}
	}

	.label {
		display: none;
		font-size: 0.8em;
		margin-top: 7px;

		@media #{$desktop} {
			display: block;
		}
	}
}